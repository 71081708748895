import { createContext } from 'react';

export interface DiscordServerContextValues {
  serverId: string | undefined;
  inviteId: string | undefined;
}

const DiscordServerContext = createContext<DiscordServerContextValues>({ serverId: undefined, inviteId: undefined });

DiscordServerContext.displayName = 'DiscordServerContext';
export default DiscordServerContext;
