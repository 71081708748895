import { AxiosInstance } from 'axios';
import { createContext } from 'react';

export interface HttpClientContextValues {
  getUnsplashClient?: () => Promise<AxiosInstance>;
}

const HttpClientContext = createContext<HttpClientContextValues>({
  getUnsplashClient: undefined,
});

export default HttpClientContext;
