import React, { lazy, Suspense } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import TemplateProvider from './Shared/Providers/TemplateProvider';
import ConfigurationProvider from './Shared/Providers/ConfigurationProvider';
import HttpClientProvider from './Shared/Providers/HttpClientProvider';

import FullPageLoader from './Shared/Components/Loaders/FullPageLoader';

const FrontpageContainer = lazy(() => import('./Domain/Frontpage/FrontpageContainer'));

const App: React.FC = () => {
  return (
    <TemplateProvider>
      <Suspense fallback={<FullPageLoader />}>
        <ConfigurationProvider>
          <HttpClientProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<FrontpageContainer />} />
              </Routes>
            </BrowserRouter>
          </HttpClientProvider>
        </ConfigurationProvider>
      </Suspense>
    </TemplateProvider>
  );
};

App.displayName = 'App';
export default App;
