import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';

import DraculaTheme from '../Themes/DraculaTheme';

interface TemplateProviderProps {
  children: React.ReactNode
}

const TemplateProvider = ({ children }: TemplateProviderProps) => {
  return <ThemeProvider theme={DraculaTheme}>{children}</ThemeProvider>;
};

TemplateProvider.displayName = 'TemplateProvider';
export default TemplateProvider;
