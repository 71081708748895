import React from 'react';
import UnsplashContext from 'Shared/Contexts/UnsplashContext';

import DiscordServerContext from '../Contexts/DiscordServerContext';

interface ConfigurationProviderProps {
    children: React.ReactNode
}

const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
  return (
    <UnsplashContext.Provider value={{ client_id: process.env.REACT_APP_UNSPLASH_CLIENT_ID }}>
      <DiscordServerContext.Provider value={{ serverId: process.env.REACT_APP_DISCORD_SERVER_ID, inviteId: process.env.REACT_APP_DISCORD_INVITE_ID }}>
        {children}
      </DiscordServerContext.Provider>
    </UnsplashContext.Provider>
  );
};

ConfigurationProvider.displayName = 'ConfigurationProvider';
export default ConfigurationProvider;
