import { createContext } from 'react';

export interface UnsplashContextValues {
  client_id: string | undefined;
}

const UnsplashContext = createContext<UnsplashContextValues>({ client_id: undefined });

UnsplashContext.displayName = 'UnsplashContext';
export default UnsplashContext;
