import React, { useContext } from 'react';
import Axios from 'axios';

import HttpClientContext from '../Contexts/HttpClientContext';
import UnsplashContext from '../Contexts/UnsplashContext';

interface HttpClientProviderProps {
    children: React.ReactNode
}

const HttpClientProvider = ({ children }: HttpClientProviderProps) => {
  const unsplashConfig = useContext(UnsplashContext);

  const getUnsplashClient = async () =>
    Axios.create({
      baseURL: 'https://api.unsplash.com',
      headers: {
        Authorization: unsplashConfig.client_id ? `Client-ID ${unsplashConfig.client_id}` : "",
      },
    });

  return <HttpClientContext.Provider value={{ getUnsplashClient }}>{children}</HttpClientContext.Provider>;
};

HttpClientProvider.displayName = 'HttpClientProvider';
export default HttpClientProvider;
