import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '98vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FullPageLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress size={128} />
    </Box>
  );
};

FullPageLoader.displayName = 'FullPageLoader';
export default FullPageLoader;
