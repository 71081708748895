import {createTheme} from '@material-ui/core/styles';

const Dark = "#282a36";
const Grey = "#44475a";
const White = "#f8f8f2";
const Cyan = "#8be9fd";
const Green = "#50fa7b";
const Orange = "#ffb86c";
const Pink = "#ff79c6";
const Purple = "#bd93f9";
const Red = "#ff5555";
// const Yellow = "#f1fa8c";

export default createTheme({
  palette: {
    background: {
      default: Dark,
      paper: Dark,
    },
    primary: {
      main: Green
    },
    secondary: {
      main: Pink
    },
    success: {
      main: Green
    },
    info: {
      main: Cyan
    },
    warning: {
      main: Orange
    },
    error: {
      main: Red
    },
    text: {
      primary: White,
      secondary: Grey,
    },
    action: {
      hover: Purple,
      focus: Pink
    }
  }
})